import loadable from '@react-loadable/revised';
import SvgComet from 'assets/animator/commet.inline.svg';
import FooterLP from 'components/FooterLP';
import Layout from 'components/Layout';
import SeoLazy from 'components/SeoLazy';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect } from 'react';
import useInView from 'react-cool-inview';

const Planet = loadable({
	loader: () => import('assets/jsx/Planet'),
	loading: () => <div />
});

const LazyLoadIndex = loadable({
	loader: () => import('components/pages/shipping/view/LazyLoadIndex'),
	loading: () => <div />
});


export default function ShippingPage() {
	const { t } = useTranslation();
	const { observe, unobserve, inView } = useInView({});

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		if(inView) {
			unobserve();
		}
	}, [inView]);

	return (
		<Layout hideLanguage>
			<div className='mb-80'>
				<div className='layout relative'>
					<div className='hidden sm:block sm:w-70/100 xl:w-65/100 2xl:w-83/100 absolute sm:-right-5/100 xl:-right-15/100 2xl:-right-20/100 top-0 sm:pt-20 lg:pt-30 xl:pt-40'>
						<StaticImage src='../../../assets/animator/images/astronaut-desktop.png' alt="" placeholder='none' />
					</div>

					<h1 className='sm:w-60/100 sm:text-shadow pt-20 sm:pt-50 lg:pt-60 xl:pt-100'>{t('pageShipping.h1')}<span className='text-green-1 font-normal text-shadow-none'>_</span></h1>

					<div className='w-full sm:w-60/100 2xl:h-160'>
						<p>{t('pageShipping.desc.desc1')}</p>
						<ul className='list-disc ml-16 mb-0'>
							<li className='mb-0'><p>{t('pageShipping.desc.li1')}</p></li>
							<li className='mb-0'><p>{t('pageShipping.desc.li2')}</p></li>
							<li className='mb-0'><p>{t('pageShipping.desc.li3')}</p></li>
						</ul>
						<p className='mb-25'>{t('pageShipping.desc.desc2')}</p>
						<p>{t('pageShipping.desc.desc3')}</p>
					</div>

					
					<div className='relative w-full mt-10 sm:hidden'>
						<StaticImage src='../../../assets/animator/images/astronaut-mobile.png' alt="" placeholder='none' />
						<Planet className='w-50 h-40 absolute left-0 top-0 mt-15' />
					</div>

					<div className='hidden sm:flex mt-20 ml-15 xl:mt-50 2xl:-mt-40 xl:ml-40'>
						<Planet className='w-140 h-100 mt-60' />
						<div className='w-10 h-10 ml-10 lg:ml-30 lg:mt-30 xl:ml-80 xl:mt-40'>
							<StaticImage src='../../../assets/animator/circle.svg' alt="" placeholder='none' />
						</div>
						<div className='hidden 2xl:block 2xl:mt-150'>
							<SvgComet tw='w-60 h-60' />
						</div>
					</div>
				</div>
			</div>


			<div className='mb-20'>
				<div className='layout relative sm:h-350 xl:h-400 3xl:h-550'>
					<div className='hidden sm:block w-60/100 absolute left-0 top-0 sm:-mt-50 sm:-ml-20'>
						<StaticImage src='../../../assets/shipping/images/picture1.png' alt="" placeholder='none' />
					</div>
            
					<div className='sm:text-right'>
						<h2 className='mb-24 mt-80 sm:mt-60'>{t('pageShipping.howItWork.h2')}<span className='text-green-1 font-normal text-shadow-none'>_</span></h2>
						<div className='flex justify-start flex-col sm:w-40/100 text-left ml-auto'>
							{`${t('pageShipping.howItWork.listTitle')}:`}
							<ol className='list-decimal'>
								<li className='mb-0'><p>{t('pageShipping.howItWork.li1')}</p></li>
								<li className='mb-0'><p>{t('pageShipping.howItWork.li2')}</p></li>
								<li className='mb-0'><p>{t('pageShipping.howItWork.li3')}</p></li>
								<li className='mb-0'><p>{t('pageShipping.howItWork.li4')}</p></li>
								<li className='mb-0'><p>{t('pageShipping.howItWork.li5')}</p></li>
							</ol>
							
						</div>
						<p ref={observe} className='w-full sm:w-60/100 sm:ml-auto'>
							<div className='hidden sm:block pl-200 w-85/100 ml-auto mt-20'>
								<StaticImage src='../../../assets/animator/images/astronaut-comet-desktop.png' alt="" />
							</div>
						</p>
					</div>
            
					{inView && (
						<div className='sm:hidden w-full mt-15'>
							<StaticImage src='../../../assets/shipping/images/picture1.png' alt="" />
						</div>
					)}
				</div>
			</div>


			<SeoLazy
				isOpen={!inView}
				items={[
					{ id: 1, tag: 'h2', text: t('pageShipping.whyItWorth.h2') },
					{ id: 2, tag: 'p', text: t('pageShipping.whyItWorth.section1.title') },
					{ id: 3, tag: 'p', text: t('pageShipping.whyItWorth.section1.l1') },
					{ id: 4, tag: 'p', text: t('pageShipping.whyItWorth.section1.l2') },
					{ id: 5, tag: 'p', text: t('pageShipping.whyItWorth.section1.l3') },
					{ id: 6, tag: 'p', text: t('pageShipping.whyItWorth.section1.l4') },
					{ id: 7, tag: 'p', text: t('pageShipping.whyItWorth.section2.title') },
					{ id: 8, tag: 'p', text: t('pageShipping.whyItWorth.section2.desc') },
					{ id: 9, tag: 'p', text: t('pageShipping.whyItWorth.section3.title') },
					{ id: 10, tag: 'p', text: t('pageShipping.whyItWorth.section3.desc1') },
					{ id: 11, tag: 'p', text: t('pageShipping.whyItWorth.section3.desc2') },
					{ id: 12, tag: 'p', text: t('pageShipping.calculator.h2') },
					{ id: 13, tag: 'p', text: t('pageShipping.calculator.desc') },
					{ id: 14, tag: 'p', text: t('pageShipping.calculator.bold') },
					{ id: 15, tag: 'h2', text: t('pageShipping.whylovefq.h2') },
					{ id: 16, tag: 'p', text: t('pageShipping.whylovefq.list.li1') },
					{ id: 17, tag: 'p', text: t('pageShipping.whylovefq.list.li2') },
					{ id: 18, tag: 'p', text: t('pageShipping.whylovefq.list.li3') },
					{ id: 19, tag: 'p', text: t('pageShipping.whylovefq.list.li4') },
					{ id: 20, tag: 'p', text: t('pageShipping.whylovefq.list.li5') },
					{ id: 21, tag: 'p', text: t('pageShipping.whylovefq.list.li6') },
					{ id: 22, tag: 'h2', text: t('pageShipping.benefitsForShipper.list.h2') },
					{ id: 23, tag: 'h3', text: t('pageShipping.benefitsForShipper.list.li1') },
					{ id: 24, tag: 'h3', text: t('pageShipping.benefitsForShipper.list.li2') },
					{ id: 25, tag: 'h3', text: t('pageShipping.benefitsForShipper.list.li3') },
					{ id: 26, tag: 'h3', text: t('pageShipping.benefitsForShipper.list.li4') },
					{ id: 27, tag: 'h3', text: t('pageShipping.benefitsForShipper.list.li5') },
					{ id: 28, tag: 'h3', text: t('pageShipping.benefitsForShipper.list.li6') },
					{ id: 29, tag: 'h3', text: t('pageShipping.benefitsForShipper.list.li7') },
					{ id: 30, tag: 'h3', text: t('pageShipping.benefitsForShipper.list.li8') },
					{ id: 31, tag: 'h2', text: t('pageShipping.demo.h2') },
					{ id: 32, tag: 'p', text: t('pageShipping.demo.desc1') },
				]}
			/>
            
			{inView && <LazyLoadIndex />}
			<FooterLP isTruckerPage />
		</Layout>
	);
}
